@media only screen and (max-width: 600px) {
  .masthead {
    padding: 100px;
  }

  .text-cc {
    font-size: 2.4rem;
  }

  .text-poscc {
    /* position: absolute; */
    /* top: 50%; */
    top: 0;
    margin: auto;
    left: auto;
    margin-left: 10px;
    margin-right: 10px;
  }

  #counter,
  #counter2,
  #counter3 {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
  }

  #yrsprojcli {
    background: url("../img/worldmap.png") no-repeat, #ffffff;
    background-size: cover, auto;
  }

  .ourclientstitle {
    display: none;
  }

  .ourclienticon {
    width: auto;
  }

  .gallery-container-text {
    margin: auto;
    width: 100%;
    left: 0;
  }

  .imgvr {
    height: auto;
    width: 100%;
  }

  .banner_main .owl-nav {
    top: 0px;
    top: -13%;
  }

  .verticalflip p {
    font-size: 20px !important;
    margin-top: 1.5rem;
  }

  .verticalflip span {

    left: 120px;
    top: 0;
  }

  span.counter2 {
    font-size: 40px !important;
    font-weight: 600 !important;
  }

  .homepage_client .owl-carousel .owl-item img {
    width: 130px !important;
    height: auto;
    object-fit: contain;
    filter: invert(1);
    background-blend-mode: color-burn;
  }

  .wtime-img {
    margin: 0;
    padding: 0;
  }

  .what_wetext p {
    font-size: 14px !important;
    width: 80% !important;
    font-weight: 500 !important;
  }

  .slider-single-content p {
    width: 100%;
  }

  .abtus-content {
    padding: 0;
    margin: 0;
  }

  .abtus-content>h1,
  .abtus-content>p {
    padding: 10px;
    margin: 10px;
  }

  .whatwedo_divdiv {
    /* padding: 50px !important; */
  }

  .whatwedo_mains {
    cursor: pointer;
  }

  .wwtitle {
    padding: 10px;
    font-weight: 500 !important;
  }

  .maps-maps {
    padding: 10px !important;
    margin: 10px;
  }

  .contactus {
    padding-top: 20px;
  }
  .react-3d-carousel{
    height: 500px !important;
  }
}